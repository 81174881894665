<template>
  <div>
    <MainNavbar />
    <MainBanner />
    <AboutUs />
    <CoreFeatures />
    <BestOnlineLearningPlatform />
    <SubscribeNewsletter />
    <PopularTopics />
    <MainFooter />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import MainNavbar from "../components/Layouts/MainNavbar.vue";
import MainBanner from "../components/HomeOne/MainBanner.vue";
import AboutUs from "../components/HomeOne/AboutUs.vue";
import CoreFeatures from "../components/HomeOne/CoreFeatures.vue";
import BestOnlineLearningPlatform from "../components/HomeOne/BestOnlineLearningPlatform.vue";
import SubscribeNewsletter from "../components/HomeOne/SubscribeNewsletter.vue";
import PopularTopics from "../components/HomeOne/PopularTopics.vue";
import MainFooter from "../components/Layouts/MainFooter.vue";

export default defineComponent({
  name: "HomeOnePage",
  components: {
    MainNavbar,
    MainBanner,
    AboutUs,
    CoreFeatures,
    BestOnlineLearningPlatform,
    SubscribeNewsletter,
    PopularTopics,
    MainFooter,
  },
});
</script>