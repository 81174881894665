<template>
  <div class="edu-topics-area pt-100 pb-100">
    <div class="container">
      <div class="edu-section-title">
        <p class="sub-title">Award Categories</p>
        <h2>The <span class="shape02">InspirEd  Award</span><br/>Categories</h2>
      </div>
      <div class="row justify-content-center">
        <div class="col-lg-3 col-md-6 col-sm-12">
          <a href="#" class="topic-box">
            <div class="single-topic-box lg-01">
              <div class="icon">
                <img src="../../assets/img/icon/t1.svg" alt="icon" />
              </div>
              <div class="content">
                <h3>Heart of the Classroom Award</h3>
                <p>For a teacher who demonstrates exceptional compassion,
                  dedication, and a genuine connection with students.</p>
              </div>
            </div>
          </a>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12">
          <a href="#" class="topic-box">
            <div class="single-topic-box lg-02">
              <div class="icon">
                <img src="../../assets/img/icon/t2.svg" alt="icon" />
              </div>
              <div class="content">
                <h3>Inspirational Educator Award</h3>
                <p>For a teacher whose passion and enthusiasm for teaching
                  inspire and motivate both students and colleagues.</p>
              </div>
            </div>
          </a>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12">
          <a href="#" class="topic-box">
            <div class="single-topic-box lg-03">
              <div class="icon">
                <img src="../../assets/img/icon/t3.svg" alt="icon" />
              </div>
              <div class="content">
                <h3>Student Advocate Award</h3>
                <p>For a teacher who consistently goes above and beyond to
                  support and champion individual student needs and successes.</p>
              </div>
            </div>
          </a>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12">
          <a href="#" class="topic-box">
            <div class="single-topic-box lg-02">
              <div class="icon">
                <img src="../../assets/img/icon/t4.svg" alt="icon" />
              </div>
              <div class="content">
                <h3>Impactful Mentor Award</h3>
                <p>For a teacher who provides profound guidance and support,
                  positively shaping the lives of their students and peers.</p>
              </div>
            </div>
          </a>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12">
          <a href="#" class="topic-box">
            <div class="single-topic-box lg-03">
              <div class="icon">
                <img src="../../assets/img/icon/t5.svg" alt="icon" />
              </div>
              <div class="content">
                <h3>Empathy in Education Award</h3>
                <p>For a teacher who shows remarkable understanding and
                  empathy, creating a nurturing and supportive learning environment.</p>
              </div>
            </div>
          </a>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12">
          <a href="#" class="topic-box">
            <div class="single-topic-box lg-04">
              <div class="icon">
                <img src="../../assets/img/icon/t6.svg" alt="icon" />
              </div>
              <div class="content">
                <h3>Transformative Educator Award</h3>
                <p>For a teacher whose innovative approaches and
                  personalized care have significantly transformed their students' educational experiences.</p>
              </div>
            </div>
          </a>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12">
          <a href="#" class="topic-box">
            <div class="single-topic-box lg-05">
              <div class="icon">
                <img src="../../assets/img/icon/t7.svg" alt="icon" />
              </div>
              <div class="content">
                <h3>Joy of Learning Award</h3>
                <p>For a teacher who makes learning an enjoyable and fulfilling
                  experience, fostering a love for knowledge in their students.</p>
              </div>
            </div>
          </a>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12">
          <a href="#" class="topic-box">
            <div class="single-topic-box lg-01">
              <div class="icon">
                <img src="../../assets/img/icon/t8.svg" alt="icon" />
              </div>
              <div class="content">
                <h3>Commmitment to Growth Award</h3>
                <p>For a teacher who shows a deep personal commitment to
                  their own development and the continuous improvement of their teaching practice.</p>
              </div>
            </div>
          </a>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12">
          <a href="#" class="topic-box">
            <div class="single-topic-box lg-01">
              <div class="icon">
                <img src="../../assets/img/icon/t1.svg" alt="icon" />
              </div>
              <div class="content">
                <h3>Encourager Award</h3>
                <p>For a teacher who consistently provides encouragement and positive
                  reinforcement, helping students build confidence and resilience.</p>
              </div>
            </div>
          </a>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12">
          <a href="#" class="topic-box">
            <div class="single-topic-box lg-02">
              <div class="icon">
                <img src="../../assets/img/icon/t2.svg" alt="icon" />
              </div>
              <div class="content">
                <h3>Cherished Educator Award</h3>
                <p>For a teacher who has made a memorable and lasting impact
                  on their students' lives, earning their heartfelt appreciation and respect.</p>
              </div>
            </div>
          </a>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12">
          <a href="#" class="topic-box">
            <div class="single-topic-box lg-03">
              <div class="icon">
                <img src="../../assets/img/icon/t3.svg" alt="icon" />
              </div>
              <div class="content">
                <h3>Legacy of Learning Award</h3>
                <p>For a teacher whose influence and dedication have created a
                  lasting impact on their students and the educational community.</p>
              </div>
            </div>
          </a>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12">
          <a href="#" class="topic-box">
            <div class="single-topic-box lg-02">
              <div class="icon">
                <img src="../../assets/img/icon/t4.svg" alt="icon" />
              </div>
              <div class="content">
                <h3>Passionate Educator Award</h3>
                <p>For a teacher whose unwavering enthusiasm and dedication
                  to their subject matter ignite a similar passion in their students.</p>
              </div>
            </div>
          </a>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12">
          <a href="#" class="topic-box">
            <div class="single-topic-box lg-03">
              <div class="icon">
                <img src="../../assets/img/icon/t5.svg" alt="icon" />
              </div>
              <div class="content">
                <h3>Creative Spark Award</h3>
                <p>For a teacher who consistently brings creativity into their teaching
                  methods, inspiring students to think outside the box and embrace new ideas.</p>
              </div>
            </div>
          </a>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12">
          <a href="#" class="topic-box">
            <div class="single-topic-box lg-04">
              <div class="icon">
                <img src="../../assets/img/icon/t6.svg" alt="icon" />
              </div>
              <div class="content">
                <h3>Unseen Hero Award</h3>
                <p>For a teacher who performs exceptional work behind the scenes,
                  making significant contributions without seeking recognition or praise.</p>
              </div>
            </div>
          </a>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12">
          <a href="#" class="topic-box">
            <div class="single-topic-box lg-05">
              <div class="icon">
                <img src="../../assets/img/icon/t7.svg" alt="icon" />
              </div>
              <div class="content">
                <h3>Building Bridges Award</h3>
                <p>For a teacher who excels in fostering connections to create a supportive and inclusive educational environment.</p>
              </div>
            </div>
          </a>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12">
          <a href="#" class="topic-box">
            <div class="single-topic-box lg-01">
              <div class="icon">
                <img src="../../assets/img/icon/t8.svg" alt="icon" />
              </div>
              <div class="content">
                <h3>And More!</h3>
                <p>There are several more award categories that celebrate educators from all backgrounds of life.</p>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PopularTopics",
};
</script>