<template>
  <footer class="edu-footer-area">
    <div class="container">
      <div class="footer-top-area ptb-100">
        <div class="row">
          <div class="col-lg-4 col-md-6 col-sm-6">
            <div class="single-footer-widget">
              <router-link to="/" class="logo">
                <img src="https://sparklerminds.s3.ap-south-1.amazonaws.com/inspired/InspirEDAwardsLogo.png" width="150px" alt="image" />
              </router-link>
              <p>
                Join Us in Celebrating Educational Excellence. Help us shine a light on those who tirelessly work to ensure every student reaches their full potential. 
              </p>
              <ul class="social-links">
                <li>
                  <a href="https://www.facebook.com/sparklerminds" target="_blank">
                    <i class="bx bxl-facebook"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/company/sparklerminds" target="_blank">
                    <i class="bx bxl-linkedin"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/sparklerminds" target="_blank">
                    <i class="bx bxl-instagram"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-2 col-md-6 col-sm-6">
            <div class="single-footer-widget pl-5">
              <!-- <h3>Links</h3>
              <ul class="links-list">
                <li><router-link to="/services-details">Home</router-link></li>
                <li>
                  <router-link to="/services-details">About Us</router-link>
                </li>
                <li>
                  <router-link to="/services-details">Pricing</router-link>
                </li>
                <li>
                  <router-link to="/services-details">Courses</router-link>
                </li>
                <li>
                  <router-link to="/services-details">Contact Us</router-link>
                </li>
                <li><router-link to="/services-details">Blog</router-link></li>
              </ul> -->
            </div>
          </div>
          <div class="col-lg-2 col-md-6 col-sm-6">
            <div class="single-footer-widget">
              <!-- <h3>Legal</h3>
              <ul class="links-list">
                <li><router-link to="/">Legal</router-link></li>
                <li><router-link to="/about">Tearms of Use</router-link></li>
                <li>
                  <router-link to="/portfolio">Tearm & Condition</router-link>
                </li>
                <li>
                  <router-link to="/blog-grid">Payment Method</router-link>
                </li>
                <li><router-link to="/contact">Privacy Policy</router-link></li>
                <li><router-link to="/contact">Privacy Policy</router-link></li>
              </ul> -->
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-6">
            <div class="single-footer-widget pt-5">
              <h3>Newsletter</h3>
              <div class="footer-newsletter-info">
                <p>
                  Submit your nominations today and join us in celebrating tutors who inspire and lead the future of learning.
                </p>
                <a class="default-btn" href="#tally-open=mVYXMl&tally-layout=modal&tally-emoji-text=👋&tally-emoji-animation=wave&tally-auto-close=5000">Sign Up Here</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="pr-line"></div>
      <div class="footer-bottom-area">
        <p>
          © Copyright {{ currentYear }} |
          <a href="https://sparklerminds.com" target="_blank">Sparkler Minds</a> | All Rights Reserved
        </p>
      </div>
    </div>
  </footer>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "MainFooter",
  setup() {
    const currentYear = ref(new Date().getFullYear());

    return {
      currentYear,
    };
  },
});
</script>