<template>
  <div class="edu-banner-area banner01">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6 col-md-12">
          <div class="edu-banner-content">
            <h1>
              Welcome to the <span class="shape01">InspirEd</span> Tutor Awards
            </h1>
            <p>
              Recognizing Excellence in Tutoring and Educational Impact!
            </p>

            <div class="subscribe-inner-area">
              <a href="#tally-open=mVYXMl&tally-layout=modal&tally-emoji-text=👋&tally-emoji-animation=wave&tally-auto-close=5000" class="default-btn">Get Started Now</a>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-12">
          <div class="edu-banner-image text-center">
            <img src="https://sparklerminds.s3.ap-south-1.amazonaws.com/inspired/main_banner_1.png" alt="banner-img" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "MainBanner",
});
</script>