<template>
  <div class="edu-platfrom-area ptb-100">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-5 col-md-12 col-sm-12">
          <div class="edu-platfrom-content">
            <p class="sub-title">How does this help you?</p>
            <h2>
              The <span class="shape02">InspirEd</span> Advantage
            </h2>
            <p>
              As a winner of the InspirED Tutor Awards, you will gain:
            </p>
            <div class="platfrom-list">
              <div class="items">
                <div class="edu-platfrom-img">
                  <img src="../../assets/img/svg/ok.svg" alt="banner-img" />
                </div>
                <div class="platfrom-single-content">
                  <h3>Recognition: Official certificate and award, enhancing your professional profile.</h3>
                </div>
              </div>
              <div class="items">
                <div class="edu-platfrom-img">
                  <img src="../../assets/img/svg/ok.svg" alt="banner-img" />
                </div>
                <div class="platfrom-single-content">
                  <h3>Spotlight: Be featured in the InsightEd Magazine and other prestigious platforms.</h3>
                </div>
              </div>
              <div class="items">
                <div class="edu-platfrom-img">
                  <img src="../../assets/img/svg/ok.svg" alt="banner-img" />
                </div>
                <div class="platfrom-single-content">
                  <h3>Networking: Join a community of forward-thinking educators committed to excellence.</h3>
                </div>
              </div>
              <div class="items">
                <div class="edu-platfrom-img">
                  <img src="../../assets/img/svg/ok.svg" alt="banner-img" />
                </div>
                <div class="platfrom-single-content">
                  <h3>Further Growth: Opportunities for mentorship, CPD, and future collaboration.</h3>
                </div>
              </div>
            </div>
            <a href="#" class="default-btn">Sign Up Here!</a>
          </div>
        </div>
        <div class="col-lg-7 col-md-12 col-sm-12">
          <div class="edu-platfrom-image">
            <img src="https://sparklerminds.s3.ap-south-1.amazonaws.com/inspired/about_us_2.png" alt="banner-img" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BestOnlineLearningPlatform",
};
</script>