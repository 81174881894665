import { createRouter, createWebHistory } from 'vue-router'

import HomeOnePage from "../Pages/HomeOnePage.vue";
import ErrorPage from "../Pages/ErrorPage.vue";

const routes = [
  {
    path: "/",
    name: "HomeOnePage",
    component: HomeOnePage,
  },
  {
    path: "/:pathMatch(.*)*",
    name: "ErrorPage",
    component: ErrorPage,
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkExactActiveClass: "active",
  scrollBehavior() {
    return { top: 0, behavior: "smooth" };
  },
})

export default router
