<template>
  <div class="edu-services-area ptb-100">
    <div class="container">
      <div class="edu-section-title">
        <p class="sub-title"></p>
        <h2>Why do the <span class="shape02">InspirEd</span> Awards exist?</h2>
      </div>
      <div class="row justify-content-center">
        <div class="col-lg-4 col-sm-6 col-md-6">
          <div class="edu-services-box">
            <div class="icon">
              <i class="bx bx-layer"></i>
            </div>
            <h3>Celebrate Excellence</h3>
            <p>
              Recognize tutors who excel in nurturing talent and curiosity.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-sm-6 col-md-6">
          <div class="edu-services-box">
            <div class="icon">
              <i class="bx bx-chalkboard"></i>
            </div>
            <h3>Highlight Innovation</h3>
            <p>
              Reward educators who bring fresh and dynamic approaches to tutoring.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-sm-6 col-md-6">
          <div class="edu-services-box">
            <div class="icon">
              <i class="bx bxs-network-chart"></i>
            </div>
            <h3>Foster Growth</h3>
            <p>
              Encourage the professional development of tutors through recognition and support.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CoreFeatures",
};
</script>