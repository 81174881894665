<template>
  <div class="edu-about-area ptb-100">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6 col-md-12">
          <div class="edu-about-image">
            <img src="https://sparklerminds.s3.ap-south-1.amazonaws.com/inspired/about_us_1.png" alt="banner-img" />
          </div>
        </div>
        <div class="col-lg-6 col-md-12">
          <div class="edu-about-content">
            <p class="sub-title">About InspirEd</p>
            <h2>
              What are the <span class="shape02">InspirEd</span> Tutor Awards?
            </h2>
            <p>
              The InspirED Tutor Awards celebrate the exceptional educators who go beyond the classroom to inspire, engage, and shape the minds of future leaders.<br/><br/>These awards acknowledge the dedication, creativity, and passion of tutors across various educational sectors, ensuring that their hard work in empowering students does not go unnoticed.
            </p>
            <div class="about-list">
              <div class="items">
                <div class="about-single-content">
                  <div class="icon2">
                    <img src="../../assets/img/svg/light.svg" alt="icon" />
                  </div>
                  <div class="content">
                    <h3>Our Vision</h3>
                    <p>
                      To celebrate the ‘Sparklers’—tutors who ignite creativity and shape the future of education.
                    </p>
                  </div>
                </div>
              </div>
              <div class="items">
                <div class="about-single-content">
                  <div class="icon2">
                    <img src="../../assets/img/svg/target.svg" alt="icon" />
                  </div>
                  <div class="content">
                    <h3>Our Mission</h3>
                    <p>
                      To honor outstanding tutors, foster innovation in teaching, and build a community of educators who inspire lifelong learning.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <!-- <router-link to="/about" class="default-btn">
              Sign Up
            </router-link> -->
            <a href="#tally-open=mVYXMl&tally-layout=modal&tally-emoji-text=👋&tally-emoji-animation=wave&tally-auto-close=5000" class="default-btn">Sign Up Here</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutUs",
};
</script>