<template>
  <div class="subscribe-area pt-100 pb-70">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6 col-md-12 col-sm-12">
          <div class="subcribe-content">
            <img src="../../assets/img/svg/big-mail.svg" alt="mail" />
            <h2>Sign Up to Our Newsletter to stay updated</h2>
          </div>
        </div>
        <div class="col-lg-6 col-md-12 col-sm-12">
          <div class="subscribe-from text-end">
            <a href="#tally-open=mVYXMl&tally-layout=modal&tally-emoji-text=👋&tally-emoji-animation=wave&tally-auto-close=5000" class="default-btn">Sign Up Here</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SubscribeNewsletter",
};
</script>