import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MainNavbar = _resolveComponent("MainNavbar")!
  const _component_MainBanner = _resolveComponent("MainBanner")!
  const _component_AboutUs = _resolveComponent("AboutUs")!
  const _component_CoreFeatures = _resolveComponent("CoreFeatures")!
  const _component_BestOnlineLearningPlatform = _resolveComponent("BestOnlineLearningPlatform")!
  const _component_SubscribeNewsletter = _resolveComponent("SubscribeNewsletter")!
  const _component_PopularTopics = _resolveComponent("PopularTopics")!
  const _component_MainFooter = _resolveComponent("MainFooter")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_MainNavbar),
    _createVNode(_component_MainBanner),
    _createVNode(_component_AboutUs),
    _createVNode(_component_CoreFeatures),
    _createVNode(_component_BestOnlineLearningPlatform),
    _createVNode(_component_SubscribeNewsletter),
    _createVNode(_component_PopularTopics),
    _createVNode(_component_MainFooter)
  ]))
}